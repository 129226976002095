@import "./variables";

.size-selector-wrapper {
  height: 3rem;
  display: flex;
  align-content: center;
  justify-content: space-evenly;
  margin: 0.25rem auto;
  font-family: $poppins;
}

.size-selector-section {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 2px solid transparent;
  border-bottom: $rich-black-2 2px solid;
  color: $outer-space-5;
  font-weight: 400;
  font-size: 0.65rem;
  user-select: none;
  outline: none;

  &:first-child {
    border-top-left-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  &:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }

  &:focus {
    border-color: $light-green;
  }

  /*Range Reset*/
  input[type="range"] {
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    cursor: pointer;
    margin: auto 0.5rem;
  }

  /* Removes default focus */
  input[type="range"]:focus {
    outline: none;
  }

  /***** Chrome, Safari, Opera and Edge Chromium styles *****/
  /* slider track */
  input[type="range"]::-webkit-slider-runnable-track {
    background-color: $rich-black;
    border-radius: 0.5rem;
    height: 0.5rem;
  }

  /* slider thumb */
  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    margin-top: -6px; /* Centers thumb on the track */

    /*custom styles*/
    background-color: $light-green;
    height: 1.25rem;
    width: 1.25rem;
  }

  input[type="range"]:focus::-webkit-slider-thumb {
    border: 1px solid $light-green;
    outline: 3px solid $light-green;
    outline-offset: 0.125rem;
  }

  input[type="range"]:hover::-webkit-slider-thumb {
    border: 1px solid $light-green;
    outline: 3px solid $light-green;
    outline-offset: 0.125rem;
  }

  /******** Firefox styles ********/
  /* slider track */
  input[type="range"]::-moz-range-track {
    background-color: $light-green;
    border-radius: 0.5rem;
    height: 0.5rem;
  }

  /* slider thumb */
  input[type="range"]::-moz-range-thumb {
    border: none; /*Removes extra border that FF applies*/
    border-radius: 0; /*Removes default border-radius that FF applies*/

    /*custom styles*/
    background-color: $light-green;
    height: 2rem;
    width: 1rem;
  }

  input[type="range"]:focus::-moz-range-thumb {
    border: 1px solid $light-green;
    outline: 3px solid $light-green;
    outline-offset: 0.125rem;
  }

  input[type="range"]:hover::-moz-range-thumb {
    border: 1px solid $light-green;
    outline: 3px solid $light-green;
    outline-offset: 0.125rem;
  }
}

.size-selector-section-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto 0.25rem auto 0;
  padding: 0;
}

.size-selector-section-active {
  background: $rich-black-2;
  border: $gunmetal-2 2px solid;
  color: #ffffff;
}

.size-selector-button {
  &:hover,
  &:active {
    background: $dark-red;
    border: 2px transparent solid;
    // border: 2px  transparent;
    color: black;
    font-weight: 500;
    // transition: all 200ms ease-in;
  }
}

/* Medium devices */
@media only screen and (min-width: 769px) {
  .size-selector-wrapper {
    width: 60svw;
    margin: 0.25rem auto;
  }
  .size-selector-section {
    font-size: 0.85rem;
  }
}

/* Large devices such as laptops (1024px and up) */
@media only screen and (min-width: 1025px) {
  .size-selector-wrapper {
    width: 50lvw;
    // margin: 1rem auto;
  }
}

/* Largest devices such as desktops (1280px and up) */
@media only screen and (min-width: 1201px) {
  .size-selector-wrapper {
    width: 35lvw;
  }
}
