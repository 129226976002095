$logo-animation: logo-color-change;
$brand-animation: brand-color-change;

@keyframes logo-color-change {
  0% {
    fill: $outer-space-3;
  }
  50% {
    fill: $outer-space-2;
  }
  100% {
    fill: $outer-space-3;
  }
}

@keyframes brand-color-change {
  0% {
    color: $outer-space-3;
  }
  50% {
    color: $outer-space-2;
  }
  100% {
    color: $outer-space-3;
  }
}
