@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

$primary-color: #646cff;
$primary-dark: #000000;
$accent-color: #c084ff;
$bg-color: #000000;
$bg-gradient: #000000;
$header-bg: #161134;
$light-blue: #52acff;
$dull-blue: #4286c5;
$blue: #3c8aff;
$violet: #431c64;
$light-green: #94db77;
$green: #71c94e;
$dark-green: #61a646;
$lime: #d2ff00;
$gold: #ebc904;
$yellow: #ffd200;
$pink: #c81f74;
$red: #f06060;
$dark-red: #ca3131;
$orange: #ff9600;
$light-orange: #ffc472;
$dark-gray: #212730;
$gray: #6a7789;
$light: #f3f9ff;
$light-gray: #d0d0d0;
$test: #2f354f;
$border-radius: 0;
$poppins: "poppins", sans-serif;

/* SCSS HEX */
$outer-space-5: #dae5ee;
$outer-space-4: #b6c6d3;
$outer-space-3: #98a7b3;
$outer-space-2: #586773;
$outer-space: #49555f;
$charcoal: #3f4a53;
$charcoal-2: #313d47;
$gunmetal: #242f38;
$gunmetal-2: #1b252c;
$rich-black: #141d23;
$rich-black-2: #10181e;
$rich-black-3: #090f13;
$rich-black-4: #040709;

/* SCSS HSL */
// $outer-space: hsla(207, 13%, 33%, 1);
// $charcoal: hsla(207, 14%, 29%, 1);
// $charcoal-2: hsla(207, 18%, 24%, 1);
// $gunmetal: hsla(207, 22%, 18%, 1);
// $gunmetal-2: hsla(205, 24%, 14%, 1);
// $rich-black: hsla(204, 27%, 11%, 1);
// $rich-black-2: hsla(206, 30%, 9%, 1);
// $rich-black-3: hsla(204, 36%, 5%, 1);
