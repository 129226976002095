@import "./variables.scss";

.toast-error {
  border-radius: $border-radius;
  background: $rich-black-3;
  color: $dark-red;
  font-size: 1rem;
}

.toast-success {
  border-radius: $border-radius;
  background: $rich-black-3;
  color: $light-green;
  font-size: 1rem;
}
