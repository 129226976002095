@import "./variables";
@import "./animations.scss";

.loader-wrapper {
  height: 100dvh;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  h4 {
    margin-left: 1rem;
    font-weight: 500;
    color: $outer-space-5;
    animation: $brand-animation 10s infinite;
  }
}

.loader {
  width: 75px;
  height: 75px;
  border: 4px dotted $green;
  border-style: none dashed;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  animation: rotation 2s linear infinite;
  z-index: 2;
}

.loader::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border: 3px dotted $outer-space;
  border-style: none solid;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  animation: rotationBack 1s linear infinite;
  transform-origin: center center;
  z-index: 2;
}

.auto-prompt-loader {
  width: 30px;
  height: 30px;
  border: 2px dotted $gray;
  border-style: solid solid dotted dotted;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  animation: rotation 2s linear infinite;
  z-index: 2;
}
.auto-prompt-loader::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border: 2px dotted $green;
  border-style: solid solid dotted;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  animation: rotationBack 1s linear infinite;
  transform-origin: center center;
  z-index: 2;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes rotationBack {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
