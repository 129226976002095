@import "./variables";

.button {
  margin: auto;
  padding: 0.9rem;
  background: $charcoal-2;
  border-radius: $border-radius;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: 2px solid transparent;
  width: auto;
  font-size: 0.8rem;
  font-weight: 500;
  font-family: $poppins, sans-serif;
  cursor: pointer;
  transition: all 200ms ease;
  user-select: none;

  &:hover {
    filter: brightness(1.1);
    transition: all 200ms ease;
  }

  &:focus-visible {
    border: 2px dashed $rich-black-2;
  }
}

.button-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  gap: 0.25rem;
}

.button-row-menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 1rem;
  gap: 0.5rem;
}

.btn-small {
  padding: 0.75rem 0;
  background: $dark-gray;
  border-radius: $border-radius;
  width: auto;
  font-size: 0.85rem;
  &:hover {
    filter: brightness(1.2);
    transition: filter 200ms ease;
  }
}

.btn-block {
  width: 100%;
  margin: auto;
  &:disabled {
    &:hover {
      filter: none;
      cursor: default;
    }
  }
}

.btn-block-no-offset {
  width: 100%;
  margin: auto;
  padding: 0.75rem;

  &:disabled {
    &:hover {
      filter: none;
      cursor: default;
    }
  }
}

.btn-block-loading {
  width: 100%;
  margin: 0.5rem auto;
  padding: 0.875rem 0;
  border-radius: 2px;
  // border-radius: $border-radius;
  background: $rich-black-4;
  color: #ffffff;
  // mix-blend-mode: difference;

  &:disabled {
    &:hover {
      filter: none;
      cursor: default;
    }
  }
}

.btn-green {
  background: $green;
  color: #000000;
}

.btn-red {
  background: linear-gradient(to bottom right, $red, $dark-red);
  color: #000000;
}

.google-btn {
  background: $dark-red;
  color: white;
  font-weight: 500;
  padding-right: 1rem;
  padding-left: 0.5rem;
  box-shadow: 1px 1px 40px -8px #ca3131c7;
}

.btn-pink {
  background: $pink;
  color: $dark-gray;
}

.btn-purple {
  background: $accent-color;
  color: $dark-gray;
}

.btn-blue {
  background: linear-gradient(to bottom right, $light-blue, $blue);
  color: $dark-gray;
}

.btn-gray {
  background: $rich-black-3;
  // border: 2px solid $rich-black-2;
  color: $outer-space-4;
  transition: all 500ms ease;

  &:hover {
    transition: all 250ms ease;
    color: $dark-green;
  }
}

.btn-clear {
  background: $rich-black-3;
  border: 2px solid transparent;
  color: $outer-space-3;
  transition: all 500ms ease;

  &:hover {
    color: $dark-red;
    // border: 2px solid $dark-red;
  }

  &:focus-visible {
    color: $dark-red;
    border: 2px dashed $dark-red;
  }
}

.btn-dark {
  background: $rich-black;
  color: $outer-space-3;
  border: 2px solid transparent;

  &:focus-visible {
    color: $light-green;
    border: 2px dashed $light-green;
  }
}

.hover-red {
  border: 2px solid transparent;

  &:focus-visible {
    color: $red;
    border: 2px dashed $red;
  }
}

.hover-green {
  &:hover {
    transition: all 250ms ease;
    color: $dark-green;
  }
}

.btn-right {
  margin: auto 1rem auto auto;
}

.btn-sign-out {
  padding: 0.5rem 0.75rem;
  font-size: 0.75rem;
  // border-radius: 5px;
  border-radius: $border-radius;
  color: white;
}

.btn-disabled {
  cursor: not-allowed;
}

.btn-icon {
  margin: auto 0.5rem;
  padding: 0;
  display: flex;
  // color: $dark-gray;
}

.download-button-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem auto;
}

/* Medium devices */
@media only screen and (min-width: 480px) {
  .btn-block {
    width: 55%;
  }
}

/* Medium devices */
@media only screen and (min-width: 769px) {
  .button-row {
    width: 60svw;
  }

  // .btn-block-no-offest,
  .btn-block {
    width: 50%;
  }

  .button {
    padding: 0.9rem 0.25rem;
  }
}

/* Large devices such as laptops (1024px and up) */
@media only screen and (min-width: 1025px) {
  .button-row {
    width: 50lvw;
  }
}

/* Largest devices such as desktops (1280px and up) */
@media only screen and (min-width: 1201px) {
  .button-row {
    width: 35lvw;
  }
}
