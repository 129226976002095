@import "./variables.scss";

.logo {
  width: 3.5rem;
  margin: auto 0;
}

.logo-svg {
  animation: $logo-animation 10s infinite;
}
