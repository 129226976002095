@import "./variables";

.prompt-input-wrapper {
  margin: 0.25rem auto;
  display: flex;
  align-items: center;
  height: 3rem;
  outline: none;
  border-radius: $border-radius;
  border: 2px solid $dark-gray;
}

.prompt-input {
  width: 100%;
  height: 3rem;
  border: none;
  background: none;
  display: flex;
  align-items: center;
  padding: 0.5rem;
  outline: none;
  font-size: 1rem;
  font-family: $poppins, sans-serif;
  color: $outer-space-4;
  // border: 2px solid $dark-gray;

  &:focus {
    border-color: $green;
  }

  &::placeholder {
    color: $outer-space-3;
    opacity: 1;
    user-select: none;
    font-weight: 400;
    font-family: $poppins, sans-serif;
  }
}

.auto-prompt-button {
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  outline: none;
  border: none;
  color: $gray;
  width: calc(3rem + 2px);
  height: 2.5rem;
  transition: all 100ms ease;

  &:hover {
    cursor: pointer;
    color: $dark-green;
    font-weight: 600;
    transition: all 100ms ease;
  }

  &:disabled {
    cursor: default;
  }
}

.auto-prompt-disabled {
  color: $dark-gray;
}

.auto-prompt-divider {
  background: $dark-gray;
  align-self: stretch;
  width: 0.2rem;
  margin: 0.25rem 0;
}

/* Medium devices */
@media only screen and (min-width: 769px) {
  .prompt-input-wrapper {
    width: 60svw;
    margin: 0.25 auto;
  }
  .auto-prompt-button {
    font-size: 0.9rem;
  }
}

/* Large devices such as laptops (1024px and up) */
@media only screen and (min-width: 1025px) {
  .prompt-input-wrapper {
    width: 50lvw;
    // margin: 1rem auto;
  }
}

/* Largest devices such as desktops (1280px and up) */
@media only screen and (min-width: 1201px) {
  .prompt-input-wrapper {
    width: 35lvw;
    // margin: 1rem auto;
  }
}
