.upload-image-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.5rem auto;
  font-family: $poppins;
}

/* Large devices such as laptops (1024px and up) */
@media only screen and (min-width: 1025px) {
  .upload-image-wrapper {
    width: 50lvw;
  }
}

/* Largest devices such as desktops (1280px and up) */
@media only screen and (min-width: 1201px) {
  .upload-image-wrapper {
    width: 35lvw;
  }
}
