.flex-container {
  display: flex;
  flex-direction: column;
  flex: 1; /* Take the entire height of the viewport */
  min-height: 100dvh;
}

.top-box {
  flex: 0 1 auto; /* Allow it to grow, but don't shrink */
  top: 0;
}

.middle-box {
  display: flex;
  flex: 0; /* Take available space */
  height: 64px;
  overflow: auto; /* Allow scrolling if content exceeds box height */
  position: absolute;
  top: 128px;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0 10px;

  .tutorial {
    position: absolute;
    top: calc(100dvh * 0.14);
    width: 100%;
    text-align: center;
    margin: 0 auto auto auto;
    user-select: none;

    // z-index: 5;
  }

  .tutorial-loading {
    position: relative;
    overflow: hidden;
    margin: 1rem auto;
    padding: 0.15rem;
    background: $gunmetal-2;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    border: none;
    width: 250px;
    transition: filter 100ms ease;
    user-select: none;
  }

  h1 {
    color: #ffffff;
    font-size: 1.5rem;
    font-weight: 500;
  }

  p {
    color: $outer-space-4;
    padding: 0.1rem;
    font-size: 0.85rem;
  }

  a {
    color: $light-green;
    padding: 0;
    text-decoration: underline;
    text-decoration-style: dashed;
    text-decoration-thickness: 1px;
  }
}

.bottom-box {
  /* Allow it to grow, but don't shrink */
  flex: 0 1 auto;
  padding: 10px;
  position: absolute;
  bottom: 0;
  width: 100%;
}

@media only screen and (min-width: 769px) {
  .middle-box {
    h1 {
      font-size: 2.1rem;
    }

    p {
      font-size: 1rem;
    }
  }
}
